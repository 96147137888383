import { useQueryClient } from "@tanstack/vue-query";
import { organizationQueries } from "~/domains/organization/queries";

export type Circle = {
  id: number;
  subdomain: string;
  background: string;
  avatar: string;
  name: string;
  brandPrimaryColor: string;
  brandSecondaryColor: string;
  members: number;
  gallery: string[];
  tags: {
    id: number;
    name: string;
    createdAt: string | null;
    updatedAt: string | null;
    pivot: {
      organizationId: number;
      tagId: number;
    };
  }[];
};

export const HIDDEN_CIRCLES = ["rareimpact"];

export async function useCircles() {
  const queryClient = useQueryClient();
  const organizations = await queryClient.fetchQuery(
    organizationQueries.list()
  );

  const circles: Circle[] = organizations
    .filter(
      (circle) =>
        NEW_CIRCLES.includes(circle.defaultUrl.url as NewCircleSlug) &&
        !HIDDEN_CIRCLES.includes(circle.defaultUrl.url)
    )
    .map((hero) => {
      const hslPrimary = hexToHsl(hero.brandPrimaryColor);
      const [h, s, l] = primaryToSecondary(hslPrimary);
      const hexSecondary = hslToHex(h, s, l);

      return {
        id: hero.id,
        subdomain: hero.defaultUrl.url,
        background: `/circles/${hero.defaultUrl.url}/banner.jpg`,
        avatar: `/circles/${hero.defaultUrl.url}/avatar.png`,
        name: hero.name,
        brandPrimaryColor: hero.brandPrimaryColor,
        brandSecondaryColor: hexSecondary,
        gallery: [
          `/circles/${hero.defaultUrl.url}/gallery/image-1.jpg`,
          `/circles/${hero.defaultUrl.url}/gallery/image-2.jpg`,
        ],
        tags: hero.tags,
        members: 0,
      };
    });

  return circles;
}
